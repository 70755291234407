html,
body,
#root,
.App {
  height: 100%;
  color: white;
  background-color: black;
  z-index: 0;
  display: flex;
  flex-direction: column;
}

.select-edge-style {
  position: absolute;
  top: 60px;
  left: 10px;
  z-index: 10;
}

/* .ol-custom-overviewmap,
.ol-custom-overviewmap.ol-uncollapsible {
        bottom: auto;
        left: auto;
        right: 0;
        top: 0;
      }

.ol-custom-overviewmap:not(.ol-collapsed)  {
        border: 1px solid green;
        width: 200px;
        height: 200px;
      }

.ol-custom-overviewmap .ol-overviewmap-map {
        border: none;
        width: 200px;
        height: 200px;
      }

.ol-custom-overviewmap .ol-overviewmap-box {
        border: 2px solid red;
      }

.ol-custom-overviewmap:not(.ol-collapsed) button{
        bottom: auto;
        left: auto;
        right: 1px;
        top: 1px;
      } */